import { InternalLink } from "lib/navigation/Link/InternalLink"
import { defaultTransitionCSS } from "lib/ui/animations/transitions"
import { UnstyledButton } from "lib/ui/buttons/UnstyledButton"
import { centerContentCSS } from "lib/ui/utils/centerContentCSS"
import { roundedCSS } from "lib/ui/utils/roundedCSS"
import React from "react"
import styled, { css } from "styled-components"

interface Props {
  name: string
  to: string
  isSelected: boolean
}

const Container = styled.div<{ isSelected: boolean }>`
  ${centerContentCSS};
  padding: 12px 20px;
  ${roundedCSS};

  position: relative;
  cursor: pointer;

  ${centerContentCSS}
  background: ${({ theme }) => theme.colors.backgroundGlass.toCssValue()};

  ${defaultTransitionCSS};

  font-weight: 500;

  color: ${({ theme }) => theme.colors.textSupporting.toCssValue()};
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundGlass2.toCssValue()};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${({ theme }) => theme.colors.backgroundGlass2.toCssValue()};
      color: ${({ theme }) => theme.colors.text.toCssValue()};
    `};
`

export const CategoryOption = ({ name, isSelected, to }: Props) => {
  return (
    <InternalLink to={to}>
      <Container isSelected={isSelected}>{name}</Container>
    </InternalLink>
  )
}
