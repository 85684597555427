import React from "react"
import { graphql } from "gatsby"
import { PostCategory } from "blog/entities/Post"
import { BlogPageQueryData, BlogTemplate } from "./Blog"

export interface CategoryContext {
  category: PostCategory
}

interface Props {
  data: BlogPageQueryData
  pageContext: CategoryContext
}

const CategoryPage = ({ data, pageContext: { category } }: Props) => {
  return <BlogTemplate data={data} selectedCategory={category} />
}

export default CategoryPage

export const pageQuery = graphql`
  query ($category: String) {
    postsRemark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { category: { eq: $category } }
        fields: { slug: { regex: "/^/[^/]+/[^/]+$/" } }
      }
    ) {
      ...PostForListFields
    }
    seriesPartsRemark: allMarkdownRemark(
      filter: {
        frontmatter: { category: { eq: $category } }
        fields: { slug: { regex: "//.+/.+/.+/" } }
      }
    ) {
      ...SeriesPartForListFields
    }
  }
`
