import { categoryName, PostCategory, postsCategories } from "blog/entities/Post"
import { HStack } from "lib/ui/Stack"
import React from "react"
import { CategoryOption } from "./CategoryOption"

interface Props {
  active?: PostCategory
}

export const CategoryOptions = ({ active }: Props) => {
  return (
    <HStack alignItems="center" gap={12}>
      <CategoryOption name="Recent" to="/blog" isSelected={!active} />
      {postsCategories.map((category) => (
        <CategoryOption
          isSelected={active === category}
          name={categoryName[category]}
          to={`/blog/${category}`}
        />
      ))}
    </HStack>
  )
}
