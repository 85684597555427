import { InternalLink } from "lib/navigation/Link/InternalLink"
import { VStack } from "lib/ui/Stack"
import { Text } from "lib/ui/Text"
import { ReactNode } from "react"
import React from "react"
import { PostCategory } from "blog/entities/Post"
import styled from "styled-components"
import { CoverImageHolder } from "../CoverImageHolder"
import { defaultBorderRadiusCSS } from "lib/ui/borderRadius"
import { defaultTransitionCSS } from "lib/ui/animations/transitions"

interface Props {
  date: string
  title: string
  image?: ReactNode
  category: PostCategory
  slug: string
  partsNumber: number
}

const ImageHolder = styled(CoverImageHolder)`
  aspect-ratio: 16 / 9;
  width: 100%;
`

const Container = styled(VStack)`
  padding: 20px;
  max-width: 400px;
  ${defaultBorderRadiusCSS};
  ${defaultTransitionCSS}
  overflow: hidden;

  &:hover {
    background: ${({ theme }) => theme.colors.outlinedHover.toCssValue()};
  }
`

export const PostItem = ({ title, slug, image }: Props) => {
  return (
    <InternalLink to={slug}>
      <Container fullHeight fullWidth gap={8} alignItems="start">
        <ImageHolder>{image}</ImageHolder>
        <Text weight="semibold">{title}</Text>
      </Container>
    </InternalLink>
  )
}
