import React from "react"
import { graphql } from "gatsby"
import { PageContainer } from "lib/ui/page/PageContainer"
import { SEO } from "seo/SEO"
import { PostsList } from "blog/components/PostsList/PostsList"
import { categoryName, PostCategory } from "blog/entities/Post"
import { PostItem } from "blog/components/PostsList/PostItem"
import { defaultMaxWidth, PageSlice } from "lib/ui/page/PageSlice"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { VStack } from "lib/ui/Stack"
import { CategoryOptions } from "blog/components/CategoryOptions"
import { Footer } from "shared/components/Footer"

interface PostsRemarkEdge {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      date: string
      title: string
      featuredImage: any
      category: PostCategory
    }
  }
}

interface SeriesPartsRemarkEdge {
  node: {
    fields: {
      slug: string
    }
  }
}

export interface BlogPageQueryData {
  postsRemark: {
    edges: PostsRemarkEdge[]
  }
  seriesPartsRemark: {
    edges: SeriesPartsRemarkEdge[]
  }
}

interface Props {
  data: BlogPageQueryData
  selectedCategory?: PostCategory
}

export const BlogTemplate = ({ data, selectedCategory }: Props) => {
  const { postsRemark, seriesPartsRemark } = data

  const seriesPartsSlugs = seriesPartsRemark.edges
    .map(({ node }) => node)
    .map(({ fields }) => fields.slug)

  const description = selectedCategory
    ? `Radzion's posts on ${categoryName[selectedCategory]}`
    : "Latest posts from Radzion"

  return (
    <PageContainer>
      <SEO title="Radzion's Blog" description={description} />
      <VStack fullWidth gap={20}>
        <div />
        <PageSlice>
          <CategoryOptions active={selectedCategory} />
        </PageSlice>
        <PageSlice maxWidth={defaultMaxWidth + 40}>
          <PostsList>
            {postsRemark.edges
              .map(({ node }) => node)
              .map(({ fields: { slug }, frontmatter }) => {
                const { date, title, featuredImage, category } = frontmatter

                const [path] = slug.split("/").reverse()
                const partsRegex = new RegExp(`.+\/${path}\/.+`)

                const image = getImage(featuredImage)

                return (
                  <PostItem
                    slug={slug}
                    key={slug}
                    date={date}
                    title={title}
                    image={
                      image ? (
                        <GatsbyImage image={image} alt={title} />
                      ) : undefined
                    }
                    category={category}
                    partsNumber={
                      seriesPartsSlugs.filter((s) => s.match(partsRegex)).length
                    }
                  />
                )
              })}
          </PostsList>
        </PageSlice>
        <div />
      </VStack>
      <Footer />
    </PageContainer>
  )
}

export const pageQuery = graphql`
  query ($category: String) {
    postsRemark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { category: { eq: $category } }
        fields: { slug: { regex: "/^/[^/]+/[^/]+$/" } }
      }
    ) {
      ...PostForListFields
    }
    seriesPartsRemark: allMarkdownRemark(
      filter: {
        frontmatter: { category: { eq: $category } }
        fields: { slug: { regex: "//.+/.+/.+/" } }
      }
    ) {
      ...SeriesPartForListFields
    }
  }
`
